import {useEffect} from "react";
import {useParams, useNavigate, Outlet} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchProjectInfo} from "../../models/ProjectInfo/action";

export default function LocalizedRoute() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const projectLanguages = useSelector(state => state.projectInfo.items?.languages);
    const {lang} = useParams();

    const setDefaultLanguage = () => {
        const defaultLang = projectLanguages && projectLanguages.length > 0
            ? projectLanguages.find(item => item.isDefault === true)
            : null;

        localStorage.setItem('dataLang', JSON.stringify(defaultLang));
    }

    useEffect(() => {
        dispatch(fetchProjectInfo())
    }, [dispatch]);

    useEffect(() => {
        if (!projectLanguages) {
            return
        }

        let dataLang = localStorage.getItem('dataLang');

        if (!dataLang) {
            setDefaultLanguage();

            dataLang = localStorage.getItem('dataLang');
        }

        const parsedDataLang = JSON.parse(dataLang);
        const shortName = parsedDataLang.shortName.toLowerCase();

        if (!lang) {
            navigate(`/${shortName}/home`);
        }

        if (projectLanguages && lang) {
            const projectLanguage = projectLanguages.find(item => lang === item.shortName);

            if (projectLanguage) {
                localStorage.setItem('dataLang', JSON.stringify(projectLanguage));
            } else {
                navigate(`/${shortName}${window.location.pathname.replace(lang + '/', '')}`);
            }
        }
    }, [projectLanguages]);

    return <Outlet/>;
}
